import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function SearchBox() {
  const [recipes, setRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState(new Set());

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchData() {
      try {
        const response = await fetch(`/record/`, { signal: abortController.signal });
        const data = await response.json();
        setRecipes(data);
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      }
    }
    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  function handleTagChange(tag, isChecked) {
    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      if (isChecked) {
        newSelectedTags.add(tag);
      } else {
        newSelectedTags.delete(tag);
      }
      return newSelectedTags;
    });
  }

  const filteredRecipes = recipes.filter(recipe => {
    const searchTerms = searchTerm.split(',').map(term => term.trim());
    const includesSearchTerm = recipe.Ingredients && searchTerms.every(term => recipe.Ingredients.toLowerCase().includes(term.toLowerCase()));
    const includesSelectedTags = recipe.Tag && [...selectedTags].every(tag => recipe.Tag.includes(tag));
    return includesSearchTerm && includesSelectedTags;
  });

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tags = queryParams.get('tags');
    if (tags) {
      setSelectedTags(new Set(tags.split(',')));
    }
  }, [location]);

  return (
    <section className="recipes-container">
      <div className="recipes-filters">
        <h3>Filter:</h3>
        <input
          type="text"
          placeholder="Search by ingredients..."
          onChange={e => setSearchTerm(e.target.value)}
        />
        <section className="tags-section">
          <h3>Tags:</h3>
          <div>
            <input
              type="checkbox"
              id="Breakfast"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Breakfast')}
            />
            <label htmlFor="Breakfast">Breakfast</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="Lunch"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Lunch')}
            />
            <label htmlFor="Lunch">Lunch</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="Dinner"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Dinner')}
            />
            <label htmlFor="Dinner">Dinner</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="Dessert"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Dessert')}
            />
            <label htmlFor="Dessert">Dessert</label>
          </div>
        </section>
      </div>
      <div className="recipes-list">
        {filteredRecipes.map(recipe => (
          <div className="recipes-in-list" key={recipe._id}>
            <Link to={'/' + recipe.URL_Name}><h4>{recipe.RecipeName}</h4></Link>
            <Link to={'/' + recipe.URL_Name}>
              <img
                src={`/images/recipes/${recipe.URL_Name}.jpg`} // Construct the image URL based on the recipe's URL_Name
                alt={recipe.RecipeName}
                className="img recipe-img"
              />
            </Link>
            <p>Prep Time: {recipe.PrepTime} | Cook Time: {recipe.CookTime}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default SearchBox;
