import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NoSleep from 'nosleep.js';

function RecipePage() {
  const [recipe, setRecipe] = useState(null);
  const [isPreventingSleep, setIsPreventingSleep] = useState(false);
  const noSleep = useRef(null);
  const { URL_Name } = useParams();

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchData() {
      try {
        const response = await fetch(`/record/${URL_Name}`, { signal: abortController.signal });
        const data = await response.json();
        setRecipe(data);
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      }
    }
    fetchData();

    noSleep.current = new NoSleep();

    return () => {
      if (isPreventingSleep) {
        noSleep.current.disable();
      }
      abortController.abort();
    };
  }, [URL_Name, isPreventingSleep]);

  const togglePreventSleep = () => {
    if (isPreventingSleep) {
      noSleep.current.disable();
      console.log('NoSleep.js disabled');
    } else {
      noSleep.current.enable();
      console.log('NoSleep.js enabled');
    }
    setIsPreventingSleep(!isPreventingSleep);
  };

  // Function to copy ingredients to clipboard
  const copyIngredientsToClipboard = () => {
    if (recipe) {
      // Create a bulleted list from the ingredients
      const ingredientsText = recipe.Ingredients.split('|')
        .map((ingredient) => `• ${ingredient}`)
        .join('\n');
      // Copy to clipboard
      navigator.clipboard.writeText(ingredientsText)
        .then(() => {
          console.log('Ingredients copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  if (!recipe) {
    return null;
  }

  const instructions = recipe.Instructions.split('|').map((instruction, index) => (
    <div className="single-instruction" key={index}>
      <header>
        <p>Step {index + 1}</p>
        <div></div>
      </header>
      <p>{instruction}</p>
    </div>
  ));

  const ingredients = recipe.Ingredients.split('|').map((ingredient, index) => (
    <div className="single-ingredient" key={index}>
      <input type="checkbox" id={ingredient} />
      <label htmlFor={ingredient}>{ingredient}</label>
    </div>
  ));

  const imageUrl = `/images/recipes/${recipe.URL_Name}.jpg`;

  return (
    <div className="page">
      <section className="recipe-hero">
        <img src={imageUrl} className="img recipe-hero-img" alt={recipe.RecipeName} />
        <article>
          <h2>{recipe.RecipeName}</h2>
          <p>{recipe.RecipeDescription}</p>
        </article>
      </section>

      {/* Slider for NoSleep.js */}
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <label style={{ marginRight: '10px' }}>
          Prevent Screen Sleep:
        </label>
        <div className="slider-container" onClick={togglePreventSleep}>
          <input
            type="checkbox"
            id="prevent-sleep"
            checked={isPreventingSleep}
            readOnly
          />
          <span className="slider"></span>
        </div>
      </div>

      <section className="recipe-content">
        <article className="first-column">
          <h4>Instructions</h4>
          {instructions}
        </article>
        <article className="second-column">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Ingredients</h4>
            {/* Copy to clipboard button */}
            <button
              className="copy-button"
              onClick={copyIngredientsToClipboard}
              aria-label="Copy Ingredients to Clipboard"
              title="Copy Ingredients"
            >
              <i className="fas fa-clipboard"></i>
            </button>
          </div>
          {ingredients}
        </article>
      </section>
    </div>
  );
}

export default RecipePage;
