import React from "react";
//import { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/main.css';
//import {Link} from "react-router-dom";

import about_image from "../styles/assets/about_mm.jpg";

function About() {
//  const [recipes, setRecipes] = useState([]);
//  const [searchTerm] = useState('');
//  const [images, setImages] = useState({});
//  const [isLoading, setIsLoading] = useState(true);

//  useEffect(() => {
//    const abortController = new AbortController();

//    async function fetchData() {
//      try {
//        setIsLoading(true);
//        const response = await fetch(`/record/`, { signal: abortController.signal });
//        const data = await response.json();
//        setRecipes(data);

        // Fetch the images for each recipe
//        const newImages = {};
//        for (const recipe of data) {
//          const imageResponse = await fetch(`/recipe_images/${recipe._id}`, { signal: abortController.signal });
//          const imageDataUrl = await imageResponse.text();
//          newImages[recipe._id] = imageDataUrl;
//        }
//        setImages(newImages);
//        setIsLoading(false);
//      } catch (err) {
//        if (err.name === 'AbortError') {
//          // fetch was aborted
//        } else {
//          console.error(err);
//        }
//      }
//    }
//    fetchData();
//
//    return () => {
//      abortController.abort();
//    };
//  }, []);
//
  // In this example, an abort controller is created and passed as an option to the fetch requests. When the component is unmounted, the useEffect hook returns a cleanup function that calls the abort method on the abort controller. This will cancel any ongoing fetch requests and prevent them from updating the state of the unmounted component.

//    const filteredRecipes = recipes.filter(recipe =>
//      recipe.Ingredients.toLowerCase().includes(searchTerm.toLowerCase())
//    );
//
    // function importAll(r) {
    //   let images = {};
    //   r.keys().map((item) => ( images[item.replace('./', '')] = r(item) ));
    //   return images;
    // }
//
    // const images = importAll(require.context('/public', false, /\.(png|jpe?g|svg)$/));
//
//    function getRandomRecipes(recipes, count) {
      // Create a copy of the input array to avoid modifying the original data
//      const recipesCopy = [...recipes];
      // Shuffle the copy of the input array
//      for (let i = recipesCopy.length - 1; i > 0; i--) {
//        const j = Math.floor(Math.random() * (i + 1));
//        [recipesCopy[i], recipesCopy[j]] = [recipesCopy[j], recipesCopy[i]];
//      }
      // Return the first `count` elements of the shuffled array
//      return recipesCopy.slice(0, count);
//    }
//
//    const randomRecipes = getRandomRecipes(filteredRecipes, 3);
//
//    if (isLoading) {
//      return (
//        <main className="page">
//          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
//        </main>
//      );
//    }

    return (
          <div>
            {/* <!-- main --> */}
            <main className="page">
                <section className="about-page">
                    <article>
                    <h2>Meet M&M!</h2>
                    <p>Moo first created her foodie instagram, goodfoodwithmoo in November 2018. Cooking good food and posting picturesque stories and posts has been one of her favorite hobbies ever since!</p>
                    <p>Her loyal sous-chef and husband, Matt, noticed that many recipe sites were full of annoying ads and our friends and family could benefit from a website where all of our favorite recipes are accessible. We believe recipes are meant to be shared, so we are happy to provide this platform for anyone and everyone to access. On this site, you’ll find some of our favorite recipes that we have collected from family, friends, and online. Please feel free to reach out using the Contact form to provide feedback or share any recipes you would like for us to add to the collection!</p>
                    <p>In short, that is why this website exists. goodfoodwithmoo.com is brought to you by Moo's creativity and taste paired with Matt's technical acumen. We hope you enjoy!</p>
                </article>
                <img src={about_image} alt="m&m" className="img about-img"/>
                </section>
            </main>
            {/* <!-- end of main --> */}
        </div>
        );
} export default About;
